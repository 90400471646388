import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.component').then(c => c.HomeComponent)
  },
  {
    path: '',
    loadComponent: () => import('./pages/owners/owners.component').then(c => c.OwnersComponent)
  },
  {
    path: 'register',
    loadComponent: () => import('./pages/register/register.component').then(c => c.RegisterComponent)
  },
  {
    path: 'v',
    loadChildren: () => import('./pages/vanity-page/vanity-page.module').then(c => c.VanityPageModule)
  },
  {
    path: 'terms-of-service',
    loadComponent: () => import('./pages/tos/tos.component').then(c => c.TosComponent)
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent)
  },
  {
    path: 'deferrable-views',
    loadComponent: () => import('./pages/deferrable-views/deferrable-views.component').then(c => c.DeferrableViewsComponent),
  },
  {
    path: "email-actions",
    data: { view: "none" },
    loadComponent: () => import('./pages/email-handler/email-handler.component').then(c => c.EmailHandlerComponent),

  },
  {
    path: "sign_agreement",
    data: { view: "renters" },
    loadComponent: () =>
      import("./pages/sign-agreement/sign-agreement.component").then(
        (c) => c.SignAgreementComponent
      ),
  },
  {
    path: "manage-booking/:bookingId",
    data: { view: "renters" },
    loadComponent: () => import('./pages/manage-booking/manage-booking.component').then(c => c.ManageBookingComponent),

  },
  {
    path: 'ssg',
    loadComponent: () => import('./pages/ssg/ssg.component').then(c => c.SsgComponent)
  },
  {
    path: 'ssr',
    loadComponent: () => import('./pages/ssr/ssr.component').then(c => c.SsrComponent)
  },
  {
    path: '**',
    loadComponent: () => import('./pages/not-found/not-found.component').then(c => c.NotFoundComponent)
  }
];
