import { Component } from '@angular/core';

@Component({
  selector: 'app-angular-icon',
  standalone: true,
  imports: [],
  template: `
    <svg width="138" height="34" viewBox="0 0 138 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1833 24.4295H8.80891L7.10692 28.5298L15.5018 33.3652L23.8967 28.5298L22.1833 24.4295Z" fill="currentColor"/>
      <path d="M19.8948 19.0658L15.5018 8.39586L11.0974 19.0658H19.8948Z" fill="currentColor"/>
      <path d="M30.9922 24.4295V6.55819L19.6303 0L30.9922 24.4295Z" fill="currentColor"/>
      <path d="M11.3734 0L0 6.55819V24.4295L11.3734 0Z" fill="currentColor"/>
      <path d="M46.6895 4.39892L38.8466 26.6692H43.159L44.999 21.1792H53.3824L55.2339 26.6692H59.5464L51.6689 4.39892H46.6895ZM46.08 17.9288L49.093 8.95865H49.2655L52.2784 17.9288H46.08Z" fill="currentColor"/>
      <path d="M71.8052 10.4862C70.9427 9.99234 69.9423 9.75114 68.7808 9.75114C67.5273 9.75114 66.4808 10.0268 65.6413 10.5781C64.7903 11.1294 64.1808 11.8759 63.8013 12.8063H63.6058V9.96937H59.8339V26.6692H63.7783V16.8836C63.7783 16.0796 63.9278 15.3905 64.2268 14.8277C64.5258 14.2649 64.9398 13.8285 65.4803 13.5299C66.0093 13.2312 66.6303 13.0819 67.3088 13.0819C68.3208 13.0819 69.1258 13.392 69.7008 14.0237C70.2757 14.6554 70.5632 15.5283 70.5632 16.6424V26.6692H74.5192V16.0337C74.5307 14.7014 74.2892 13.5643 73.8177 12.634C73.3347 11.6922 72.6677 10.9801 71.8052 10.4862Z" fill="currentColor"/>
      <path d="M87.1576 12.7259H86.9391C86.7436 12.3239 86.4561 11.8874 86.0766 11.428C85.6971 10.9686 85.1911 10.5781 84.5586 10.245C83.9261 9.91194 83.1211 9.75114 82.1321 9.75114C80.8441 9.75114 79.6826 10.0842 78.6362 10.7389C77.5897 11.3936 76.7617 12.3583 76.1522 13.6217C75.5427 14.8851 75.2322 16.4357 75.2322 18.2733C75.2322 20.088 75.5427 21.6041 76.1522 22.8101C76.7617 24.0161 77.5897 24.9234 78.6362 25.5321C79.6826 26.1409 80.8441 26.4395 82.1206 26.4395C83.0751 26.4395 83.8801 26.2902 84.5126 26.003C85.1451 25.7159 85.6626 25.3599 86.0421 24.9234C86.4331 24.4985 86.7206 24.085 86.9276 23.6715H87.1231V26.83C87.1231 28.0589 86.7551 28.9433 86.0191 29.4831C85.2831 30.023 84.3631 30.2986 83.2476 30.2986C82.4541 30.2986 81.7986 30.1952 81.2811 29.977C80.7636 29.7588 80.3496 29.4946 80.0506 29.173C79.7401 28.8514 79.5101 28.5298 79.3492 28.1968L75.7957 29.0582C76.0372 29.8392 76.4627 30.5398 77.0837 31.183C77.7047 31.8262 78.5212 32.3315 79.5331 32.7105C80.5451 33.0896 81.7641 33.2848 83.1786 33.2848C84.6851 33.2848 86.0191 33.0551 87.2036 32.5842C88.3881 32.1248 89.3195 31.4242 89.998 30.4824C90.6765 29.552 91.0215 28.369 91.0215 26.9334V9.96937H87.1346V12.7259H87.1576ZM86.7091 20.9724C86.4101 21.7305 85.9616 22.3162 85.3751 22.7182C84.7886 23.1202 84.0756 23.3269 83.2361 23.3269C82.3736 23.3269 81.6491 23.1202 81.0511 22.6952C80.4646 22.2703 80.0161 21.673 79.7171 20.915C79.4182 20.1455 79.2687 19.2611 79.2687 18.2389C79.2687 17.2282 79.4182 16.3323 79.7171 15.5398C80.0161 14.7473 80.4531 14.1156 81.0396 13.6677C81.6261 13.2083 82.3621 12.9786 83.2361 12.9786C84.0871 12.9786 84.8001 13.1968 85.3866 13.6332C85.9731 14.0697 86.4101 14.6899 86.7091 15.4709C87.0081 16.2634 87.1576 17.1822 87.1576 18.2274C87.1576 19.307 87.0081 20.2144 86.7091 20.9724Z" fill="currentColor"/>
      <path d="M102.993 19.6401C102.993 20.49 102.82 21.1906 102.464 21.7534C102.107 22.3162 101.659 22.7412 101.118 23.0168C100.578 23.304 100.014 23.4418 99.4279 23.4418C98.4849 23.4418 97.726 23.1317 97.1395 22.5115C96.5645 21.8913 96.2655 21.0528 96.2655 19.9847V9.96937H92.321V20.6049C92.321 21.9372 92.5625 23.0743 93.034 24.0161C93.5055 24.9579 94.161 25.67 95.0005 26.1524C95.84 26.6462 96.806 26.8874 97.8985 26.8874C99.1519 26.8874 100.21 26.5888 101.072 25.9916C101.935 25.3943 102.556 24.6363 102.924 23.6945H103.096V26.6577H106.914V9.96937H102.97V19.6401H102.993Z" fill="currentColor"/>
      <path d="M112.17 4.39892H108.225V26.6692H112.17V4.39892Z" fill="currentColor"/>
      <path d="M125.05 10.9571C124.394 10.5207 123.658 10.2106 122.865 10.0268C122.071 9.84303 121.266 9.73966 120.461 9.73966C119.288 9.73966 118.23 9.91194 117.264 10.2565C116.298 10.6011 115.482 11.1179 114.815 11.7956C114.148 12.4847 113.665 13.3346 113.377 14.3453L117.057 14.8622C117.253 14.2879 117.632 13.794 118.196 13.3691C118.759 12.9441 119.518 12.7374 120.484 12.7374C121.404 12.7374 122.094 12.9671 122.589 13.415C123.072 13.8629 123.313 14.4946 123.313 15.3216V15.3905C123.313 15.7695 123.175 16.0452 122.899 16.2174C122.623 16.3897 122.175 16.5276 121.565 16.608C120.956 16.6884 120.162 16.7802 119.185 16.8836C118.368 16.9755 117.586 17.1133 116.827 17.2971C116.068 17.4923 115.39 17.768 114.792 18.1355C114.194 18.503 113.711 18.9969 113.366 19.6171C113.021 20.2374 112.848 21.0184 112.848 21.9602C112.848 23.0513 113.09 23.9701 113.584 24.7167C114.079 25.4632 114.746 26.026 115.585 26.405C116.436 26.7841 117.379 26.9793 118.437 26.9793C119.311 26.9793 120.07 26.853 120.714 26.6118C121.358 26.3706 121.899 26.049 122.336 25.647C122.773 25.245 123.106 24.8086 123.348 24.3491H123.474V26.6462H127.258V15.4709C127.258 14.3568 127.051 13.438 126.648 12.7029C126.246 11.9793 125.705 11.3936 125.05 10.9571ZM123.325 20.6738C123.325 21.294 123.164 21.8568 122.853 22.3851C122.543 22.902 122.094 23.3269 121.508 23.637C120.933 23.9472 120.243 24.1079 119.449 24.1079C118.633 24.1079 117.954 23.9242 117.425 23.5566C116.896 23.1891 116.632 22.6378 116.632 21.9142C116.632 21.4089 116.77 20.9954 117.034 20.6738C117.299 20.3522 117.667 20.0995 118.138 19.9158C118.598 19.732 119.127 19.6057 119.714 19.5253C119.978 19.4908 120.289 19.4449 120.634 19.3989C120.99 19.353 121.347 19.2955 121.715 19.2266C122.083 19.1577 122.405 19.0888 122.704 18.9969C123.003 18.905 123.21 18.8132 123.325 18.7098V20.6738Z" fill="currentColor"/>
      <path d="M137.32 9.75114C137.044 9.72817 136.803 9.72817 136.584 9.72817C135.63 9.72817 134.779 9.99234 134.031 10.5207C133.284 11.049 132.766 11.7956 132.456 12.7603H132.283V9.96937H128.465V26.6692H132.41V16.8491C132.41 16.137 132.571 15.5168 132.904 14.9655C133.226 14.4257 133.675 14.0008 134.25 13.6906C134.825 13.3805 135.469 13.2312 136.182 13.2312C136.515 13.2312 136.86 13.2542 137.217 13.3001C137.573 13.3461 137.838 13.4035 138.01 13.4609V9.83154C137.815 9.79708 137.585 9.77411 137.32 9.75114Z" fill="currentColor"/>
    </svg>
  `,
})
export class AngularIconComponent {

}
