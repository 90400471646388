@if (!isMainPage()) {
<!-- <a routerLink="/" class="button back-button">
  <app-arrow-back-icon />
  Back to home</a> -->
}

<div class="page-container">
  <header [ngClass]="{isOwnerView,
      isRenterView,
      isRegisterPage,
      isTos, scrolled}">
    <div class="inner-header">
      <div class="logo" [routerLink]="['']">
        <img width="120px" [src]="_Images.logo_full" alt="Fumes Logo" />
      </div>
      <div class="links">
        <ul>
          <li>
            <!-- <button
              class="start-today-button"
              routerLink="{{ isOwnerView ? 'renters' : 'owners' }}"
            >
              <span>{{ isOwnerView ? "Rent Instead" : "Start a Fleet" }}</span>
              <mat-icon>arrow_forward</mat-icon>
            </button> -->
            <button class="start-today-button" (click)="switchApp('owner')">
              <span>Login Instead</span>
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <router-outlet />
</div>