import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { filter, map, startWith, takeUntil } from 'rxjs';
import { AngularIconComponent } from './components/icons/angular-icon.component';
import { FirebaseIconComponent } from './components/icons/firebase-icon.component';
import { ArrowBackIconComponent } from './components/icons/arrow-back-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from '@fumes/services';
import { CommonModule } from '@angular/common';
import { Images } from '@fumes/constants';
import { BaseComponent } from '@fumes/types';
import { WINDOW } from './providers/window';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AngularIconComponent, FirebaseIconComponent, ArrowBackIconComponent, MatIconModule, RouterLink],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent {
  private readonly router = inject(Router);
  private readonly common = inject(CommonService);

  public isOwnerView: boolean = true;
  public isRenterView: boolean = false;
  public isRegisterPage: boolean = false;
  public isTos: boolean = false;
  public isVanityPage: boolean = false;
  public scrolled = false;
  _Images = Images;
  private readonly isMainPage$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.url === '/'),
    startWith(true)
  );
  readonly window = inject(WINDOW);
  constructor(private activatedRoute: ActivatedRoute,
  ) { super(); }
  override ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        //@ts-ignore - this is a hack to get the data
        this.activatedRoute['firstChild']['data'].pipe(takeUntil(this.destroyed)).subscribe((data: any) => {
          // Access the data property here
          const view = data?.view;
          if (view) {
            this.isOwnerView = view.includes("owner");
            this.isRenterView = view.includes("renter");
            this.isRegisterPage = view.includes("registration");
            this.isTos = view.includes("terms");
            this.isVanityPage =
              !this.isOwnerView &&
              !this.isRenterView &&
              !this.isRegisterPage &&
              !this.isTos;
          }
        });
      });
    if (this.window) {
      this.attachScrollListener();
    }
  }

  isMainPage = toSignal(this.isMainPage$);
  switchApp(app: string) {
    const prodUrl = "fumes.app";
    const isProd = this.window.location.href.includes(prodUrl);
    if (isProd) {
      this.window.location.href = "https://owner.fumes.app"
    } else {
      this.window.location.href = "http://localhost:4200"
    }
  }
  attachScrollListener() {
    this.window.addEventListener("scroll", this.onScrollWindow.bind(this));
  }

  onScrollWindow(event: any) {
    this.scrolled = event.target.documentElement.scrollTop > 0;
  }
}
