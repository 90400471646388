import { APP_ID, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { WINDOW, windowProvider } from './providers/window';
import { DOCUMENT } from '@angular/common';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from './environments/environment';
import { LIB_ENV } from '@fumes/services';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
  { provide: APP_ID, useValue: "landing-app" },
  { provide: LIB_ENV, useValue: environment },
  provideClientHydration(),
  {
    provide: WINDOW,
    useFactory: (document: Document) => windowProvider(document),
    deps: [DOCUMENT],
  }, provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideAnalytics(() => getAnalytics()), ScreenTrackingService,
  provideFirestore(() => getFirestore()),
  provideFunctions(() => getFunctions()),
  provideMessaging(() => getMessaging()),
  providePerformance(() => getPerformance()), provideAnimationsAsync(), provideAnimationsAsync(),
  ]
};
